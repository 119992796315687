@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.taskItemList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* HTML elements */
h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

input {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 22px;
  width: 100%;
  color: #333333;
}

input:focus {
  outline: none !important;
  height: 22px;
}

/* Navigation */
.navbar {
  background-color: #f8f8f8;
  margin-bottom: 20px;
}

/* Containers */

.mainContainer {
  background-color: #f8f8f8;
  justify-content: center;
  height: calc(100vh - 87px);
  height: -o-calc(100% - 87px); /* opera */
  height: -webkit-calc(100% - 87px); /* google, safari */
  height: -moz-calc(100% - 87px); /* firefox */
}

.taskContainer,
.calContainer {
  background: #fff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}

/* .taskContainer {
  margin-right: 20px;
} */

.mainContent {
  padding: 10px 35px 10px 20px;
}

.sideNav {
  border-right: 1px solid #e6e6e6;
}

.calContainer {
  width: 26vw;
}

.taskListItemContainer {
  width: 86%;
}

.draggable {
  top: auto !important;
  left: auto !important;
}

.taskItemContainer:hover,
.addCTA:hover {
  cursor: pointer;
}

/* Copy */

.taskTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.taskTitleCompleted {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #676767;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
}

input:focus,
select:focus,
textarea:focus,
button:focus,
div:focus {
  outline: none;
}

/* Full Calendar */
.fc-timegrid-slot,
.fc-daygrid-day-frame {
  background-color: #fff;
}

.fc-toolbar,
.fc-header-toolbar {
  padding: 20px;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0em !important;
}

.fc-timegrid-axis-cushion {
  visibility: hidden !important;
}

.fc-timegrid-slot-label-cushion,
.fc-scrollgrid-shrink-cushion {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #b4b4b4;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: hidden !important;
}

.fc .fc-timegrid-divider {
  padding: 0px !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #f0f0f0 !important;
}

.fc-button,
.fc-button-primary {
  background-color: #fff !important;
  border: none !important;
  outline: none !important;
}

.fc-today-button {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.fc-icon,
.fc-today-button {
  color: #b4b4b4 !important;
}

.fc-event-title-container {
  font-size: 12px;
  font-weight: 600;
}

.fc-event-time {
  font-size: 10px !important;
}

.logoContainer {
  -webkit-flex-align: center !important;
  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  justify-content: center !important;
}
